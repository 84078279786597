import { gql } from '@apollo/client';

export const DEVICE_LIST_ITEM_FRAGMENT = gql`
  fragment DeviceListItemFragment on Device {
    ... on Node {
      id
    }
    ... on H2090 {
      model
    }
    name
    lastOnline
    nextOnline
    serialNumber
    active
    inMaintenance
  }
`;

export const DEVICE_DETAIL_FRAGMENT = gql`
  fragment DeviceDetailFragment on Device {
    ...DeviceListItemFragment
    user
    owners
    iAmOwner
    iAmUser
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
`;

export const DEVICES_QUERY = gql`
  query deviceQuery($afterCursor: String, $pageSize: Int!, $filter: DeviceFilter) {
    viewer {
      devices(first: $pageSize, after: $afterCursor, filter: $filter) {
        edges {
          node {
            ...DeviceListItemFragment
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
`;

export const DEVICE_CHANGE_ACTIVE_MUTATION = gql`
  mutation deviceChangeActiveMutation($input: DeviceChangeActiveInput!) {
    deviceChangeActive(input: $input) {
      device {
        ...DeviceListItemFragment
      }
    }
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
`;

export const DEVICE_CHANGE_IN_MAINTENANCE_MUTATION = gql`
  mutation deviceChangeInMaintenanceMutation($input: DeviceChangeInMaintenanceInput!) {
    deviceChangeInMaintenance(input: $input) {
      device {
        ...DeviceListItemFragment
      }
    }
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
`;

export const DEVICE_CHANGE_USER_MUTATION = gql`
  mutation deviceChangeUserMutation($input: DeviceChangeUserInput!) {
    deviceChangeUser(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const DEVICE_CHANGE_OWNERS_MUTATION = gql`
  mutation deviceChangeOwnersMutation($input: DeviceChangeOwnersInput!) {
    deviceChangeOwners(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;
